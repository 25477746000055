
















import { Vue, Component } from 'vue-property-decorator';

@Component
export default class extends Vue {
  get isProduction(): boolean {
    return process.env.NODE_ENV === 'production';
  }
}
