


































































































import { jwt } from '@/auth';
import NavLink from '@/components/Admin/NavLink.vue';
import { rootModule, rootNS } from '@/store';
import { serverURL } from '@/util';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
    NavLink,
  },
})
export default class extends Vue {
  @rootNS.State readonly loading!: boolean;
  drawer = true;

  get saved(): boolean { return rootModule.saved; }
  set saved(val: boolean) { rootModule.setSaved(val); }

  get loginURL(): string {
    return `${serverURL()}auth/login?redirect=${this.redirectURL}`;
  }

  get logoutURL(): string {
    return `${serverURL()}auth/logout?redirect=${this.redirectURL}`;
  }

  get redirectURL(): string {
    return encodeURIComponent(`${window.location.origin}${process.env.BASE_URL}admin/`);
  }

  get isAuthed(): boolean {
    return !!jwt;
  }

  get isAdmin(): boolean {
    return !!jwt?.user.roles.includes('admin');
  }

  get isTestEnv(): boolean {
    // Lazy "window as any" for now because can't be bothered to make a whole config type.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (window as any).config?.targetEnv === 'staging';
  }

  // Slightly hardcoded method to get a link to the production version of
  // this page from the test version, which for us is just a TLD change.
  // Not used in anything but a test environment so good enough.
  get prodURL(): string {
    return `${window.location.origin.replace('.dev', '.com')}/admin`;
  }
}
