import { io, ManagerOptions } from 'socket.io-client';

const { env } = process;
const socketURL = env.NODE_ENV === 'production' && env.VUE_APP_USEDEVSERVER !== 'true'
  ? window.location.origin : 'http://localhost:2345';
const path = `${env.NODE_ENV === 'production' && env.VUE_APP_USEDEVSERVER !== 'true'
  ? process.env.VUE_APP_BASEURL_SERVER || '/' : '/'}socket.io`;
const socketOpts: Partial<ManagerOptions> = {
  autoConnect: false,
  path,
};
export const socket = io(socketURL, socketOpts);
