/* eslint-disable max-len */

import { jwt } from '@/auth';
import Admin from '@/views/Admin.vue';
import Dashboard from '@/views/Dashboard.vue';
import Error from '@/views/Error.vue';
import Home from '@/views/Home.vue';
import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/home',
    redirect: '/',
  },
  {
    path: '/error',
    name: 'Error',
    meta: { title: 'Error' },
    component: Error,
  },
  {
    path: '/admin',
    component: Admin,
    children: [
      {
        path: '',
        name: 'admin_home',
        meta: { title: 'Admin: Home' },
        component: () => import(/* webpackChunkName: "admin_home" */ '@/views/Admin/Home.vue'),
        beforeEnter: (to, from, next) => {
          // Special "beforeEnter" that will stay on the page if not logged in at all.
          if (!jwt || jwt.user.roles.includes('admin')) return next();
          return next({ name: 'admin_error', query: { err: 'NoAdmin' } });
        },
      },
      {
        path: 'error',
        name: 'admin_error',
        meta: { title: 'Admin: Error' },
        component: () => import(/* webpackChunkName: "admin_error" */ '@/views/Admin/Error.vue'),
      },
      {
        path: 'companydata',
        name: 'admin_companydata',
        meta: { title: 'Admin: Company Data', requiresAuth: true },
        component: () => import(/* webpackChunkName: "admin_companydata" */ '@/views/Admin/CompanyData/main.vue'),
      },
      {
        path: 'apps',
        name: 'admin_apps',
        meta: { title: 'Admin: Applications', requiresAuth: true },
        component: () => import(/* webpackChunkName: "admin_apps" */ '@/views/Admin/Applications/main.vue'),
      },
      {
        path: 'events',
        name: 'admin_events',
        meta: { title: 'Admin: Events', requiresAuth: true },
        component: () => import(/* webpackChunkName: "admin_events" */ '@/views/Admin/Events/main.vue'),
      },
      {
        path: 'users',
        name: 'admin_users',
        meta: { title: 'Admin: Users', requiresAuth: true },
        component: () => import(/* webpackChunkName: "admin_users" */ '@/views/Admin/Users/main.vue'),
      },
      {
        path: 'players',
        name: 'admin_players',
        meta: { title: 'Admin: Players', requiresAuth: true },
        component: () => import(/* webpackChunkName: "admin_players" */ '@/views/Admin/Players/main.vue'),
      },
      {
        path: 'casters',
        name: 'admin_casters',
        meta: { title: 'Admin: Casters', requiresAuth: true },
        component: () => import(/* webpackChunkName: "admin_casters" */ '@/views/Admin/Casters/main.vue'),
      },
      {
        path: 'playercards',
        name: 'admin_playercards',
        meta: { title: 'Admin: Player Cards', requiresAuth: true },
        component: () => import(/* webpackChunkName: "admin_playercards" */ '@/views/Admin/PlayerCards/main.vue'),
      },
      {
        path: 'eventlog',
        name: 'admin_eventlog',
        meta: { title: 'Admin: Event Log', requiresAuth: true },
        component: () => import(/* webpackChunkName: "admin_eventlog" */ '@/views/Admin/EventLog/main.vue'),
      },
      {
        path: 'livesplit',
        name: 'admin_livesplit',
        meta: { title: 'Admin: LiveSplit Data', requiresAuth: true },
        component: () => import(/* webpackChunkName: "admin_livesplit" */ '@/views/Admin/LiveSplit/main.vue'),
      },
      {
        path: 'videoplaylists',
        name: 'admin_videoplaylists',
        meta: { title: 'Admin: Video Playlists', requiresAuth: true },
        component: () => import(/* webpackChunkName: "admin_videoplaylists" */ '@/views/Admin/VideoPlaylists/main.vue'),
      },
      {
        path: 'authorisedchannels',
        name: 'admin_authorisedchannels',
        meta: { title: 'Admin: Authorised Channels', requiresAuth: true },
        component: () => import(/* webpackChunkName: "admin_authorisedchannels" */ '@/views/Admin/AuthorisedChannels/main.vue'),
      },
      {
        path: 'commercials',
        name: 'admin_commercials',
        meta: { title: 'Admin: Commercials', requiresAuth: true },
        component: () => import(/* webpackChunkName: "admin_commercials" */ '@/views/Admin/Commercials/main.vue'),
      },
      {
        path: 'images',
        name: 'admin_images',
        meta: { title: 'Admin: Images', requiresAuth: true },
        component: () => import(/* webpackChunkName: "admin_images" */ '@/views/Admin/Images/main.vue'),
      },
      {
        path: 'sponsors',
        name: 'admin_sponsors',
        meta: { title: 'Admin: Sponsors', requiresAuth: true },
        component: () => import(/* webpackChunkName: "admin_sponsors" */ '@/views/Admin/Sponsors/main.vue'),
      },
      {
        path: 'runattempts',
        name: 'admin_runattempts',
        meta: { title: 'Admin: Run Attempts', requiresAuth: true },
        component: () => import(/* webpackChunkName: "admin_runattempts" */ '@/views/Admin/RunAttempts/main.vue'),
      },
    ],
  },
  {
    path: '/admin/*',
    redirect: '/admin',
  },
  {
    path: '/dashboard',
    component: Dashboard,
    children: [
      {
        path: '',
        name: 'dash_home',
        // route level code-splitting
        // this generates a separate chunk (<name>.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "dash_home" */ '@/views/Dashboard/Home.vue'),
      },
      {
        path: 'error',
        name: 'dash_error',
        meta: { title: 'Error' },
        component: () => import(/* webpackChunkName: "dash_error" */ '@/views/Dashboard/Error.vue'),
      },
      {
        path: 'caster/dashboard',
        name: 'dash_casterdash',
        meta: { title: 'Caster Dashboard', requiresAuth: true },
        component: () => import(/* webpackChunkName: "dash_casterdash" */ '@/views/Dashboard/CasterDashboard/main.vue'),
      },
      {
        path: 'caster/twitch',
        name: 'dash_castertwitch',
        meta: { title: 'Caster Twitch Players', requiresAuth: true },
        component: () => import(/* webpackChunkName: "dash_castertwitch" */ '@/views/Dashboard/CasterTwitchPlayers/main.vue'),
      },
      {
        path: 'observer/dashboard',
        name: 'dash_obsdash',
        meta: { title: 'Observer Dashboard', requiresAuth: true },
        component: () => import(/* webpackChunkName: "dash_obsdash" */ '@/views/Dashboard/ObserverDashboard/main.vue'),
      },
      {
        path: 'observer/twitch',
        name: 'dash_obstwitch',
        meta: { title: 'Observer Twitch Players', requiresAuth: true },
        component: () => import(/* webpackChunkName: "dash_obstwitch" */ '@/views/Dashboard/ObserverTwitchPlayers/main.vue'),
      },
      {
        path: 'scenecontrol',
        name: 'dash_scenecontrol',
        meta: { title: 'Scene Control', requiresAuth: true },
        component: () => import(/* webpackChunkName: "dash_scenecontrol" */ '@/views/Dashboard/SceneControl/main.vue'),
      },
    ],
  },
  {
    path: '/dashboard/*',
    redirect: '/dashboard',
  },
  {
    path: '*',
    redirect: '/',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  // If no authentication needed, just allow the user to access the page.
  if (!to.meta?.requiresAuth) return next();

  // Dashboard
  if (to.name?.startsWith('dashboard_') || to.fullPath.startsWith('/dashboard')) {
    if (jwt) return next();
    return next({ name: 'dash_home' });
  }

  // Admin
  if (to.name?.startsWith('admin_') || to.path.startsWith('/admin')) {
    if (jwt) {
      // If logged in and not admin, send to error page.
      if (!jwt.user.roles.includes('admin')) {
        return next({ name: 'admin_error', query: { err: 'NoAdmin' } });
      }
      return next();
    }
    // If not logged in, just send to admin homepage.
    return next({ name: 'admin_home' });
  }

  // If nothing else is matched, just go to the homepage.
  return next({ name: 'home' });
});

router.afterEach((to) => {
  if (to.meta?.title) document.title = `${to.meta.title} - Break the Record: LIVE`;
  else document.title = 'Break the Record: LIVE';
});

export default router;
