import clone from 'clone';

export function serverURL(): string {
  return process.env.NODE_ENV === 'production' && process.env.VUE_APP_USEDEVSERVER !== 'true'
    ? `${window.location.origin}${process.env.VUE_APP_BASEURL_SERVER || '/'}`
    : 'http://localhost:2345/';
}

/**
 * Checks if number needs a 0 adding to the start and does so if needed.
 * @param num Number which you want to turn into a padded string.
 * @param decimenals If to display decimals to 2 places at the end (for deltaMSToTimeStr).
 */
export function padTimeNumber(num: number, decimals = false): string {
  if (decimals) {
    return num.toFixed(2).padStart(5, '0');
  }
  return num.toString().padStart(2, '0');
}

/**
 * Converts a time string (HH:mm:ss) into milliseconds.
 * @param time Time string you wish to convert.
 */
export function timeStrToMS(time: string): number {
  const ts = time.split(':');
  if (ts.length === 1) {
    ts.unshift('00'); // Adds 0 minutes if they are not specified.
  }
  if (ts.length === 2) {
    ts.unshift('00'); // Adds 0 hours if they are not specified.
  }
  return Date.UTC(1970, 0, 1, Number(ts[0]), Number(ts[1]), Number(ts[2]));
}

/**
 * Converts milliseconds into a time string (HH:MM:SS).
 * @param ms Milliseconds you wish to convert.
 */
export function msToTimeStr(ms: number): string {
  const seconds = Math.floor((ms / 1000) % 60);
  const minutes = Math.floor((ms / (1000 * 60)) % 60);
  const hours = Math.floor(ms / (1000 * 60 * 60));
  return `${padTimeNumber(hours)}:${padTimeNumber(minutes)}:${padTimeNumber(seconds)}`;
}

/**
 * Converts delta milliseconds into a relevant time string,
 * as short as possible (with 2 decimal places).
 * @param ms Milliseconds you wish to convert.
 */
export function deltaMSToTimeStr(ms: number, showSymbol = true): string {
  const posMS = Math.abs(ms);
  const symbol = ms >= 0 ? '+' : '−';
  const seconds = (posMS / 1000) % 60;
  const minutes = Math.floor((posMS / (1000 * 60)) % 60);
  const hours = Math.floor(posMS / (1000 * 60 * 60));
  let str = showSymbol ? symbol : '';
  if (hours > 0) {
    str += `${hours}:`;
  }
  if (minutes > 0) {
    str += `${hours > 0 ? padTimeNumber(minutes) : minutes}:`;
  }
  str += `${minutes > 0 ? padTimeNumber(seconds, true) : seconds.toFixed(2)}`;
  return str;
}

export function getOrdinal(n: number): string {
  const s = ['th', 'st', 'nd', 'rd'];
  const v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
}

/**
 * Removes the database returned timestamps that indicate creation/updated times.
 * @param entity Entity/object from database.
 */
export function removeDBTimestamps<
K extends { creationTimestamp: string; updatedTimestamp: string }
>(entity: K): Omit<Partial<K>, 'creationTimestamp' | 'updatedTimestamp'> {
  const entityCopy: Partial<K> = clone(entity);
  delete entityCopy.creationTimestamp;
  delete entityCopy.updatedTimestamp;
  return entityCopy;
}

export function formatCurrencyVal(currency: 'USD' | undefined, value: number): number {
  if (currency === 'USD') return Math.floor(value / 100);
  return value;
}

export function formatCurrencyStr(currency: 'USD' | undefined, value: number): string {
  if (currency === 'USD') return `$${Math.floor(value / 100)}`;
  return value.toString();
}

// Currently very simple, could be improved to work with embeds and different ID keys.
export function updateAPIArr<T extends { id: number | string }>(
  arr: T[],
  newVal: T | null,
  oldVal: T | null,
): T[] {
  const newArr = clone(arr);
  const index = newArr.findIndex((i) => i.id === oldVal?.id);
  if (newVal && !oldVal) newArr.push(newVal);
  else if (!newVal && oldVal && index >= 0) newArr.splice(index, 1);
  if (index >= 0) {
    newArr[index] = { ...newArr[index], ...newVal };
  }
  return newArr;
}
