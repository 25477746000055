













import { Vue, Component, Prop } from 'vue-property-decorator';
import { Route } from 'vue-router';

@Component
export default class extends Vue {
  @Prop([String, Object]) readonly route!: string | Route;
  @Prop(String) readonly href!: string;
  @Prop(Boolean) readonly exact!: boolean;
  @Prop({ required: true }) readonly icon!: string;
}
