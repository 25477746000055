import Vue from 'vue';
import VueSocketIOExt from 'vue-socket.io-extended';
import App from './App.vue';
import { checkCookieToken, jwt, jwtCookie } from './auth';
import vuetify from './plugins/vuetify';
import router from './router';
import { socket } from './socket';
import { store } from './store';

Vue.config.productionTip = false;

checkCookieToken().then(() => {
  Vue.use(VueSocketIOExt, socket, {
    store,
    actionPrefix: 'SOCKET_',
    eventToActionTransformer: (actionName: string) => actionName.toUpperCase(),
  });

  if (jwt) {
    // Connects socket.io and sends JWT.
    socket.connect();
    socket.on('connect', () => {
      socket.emit('authenticate', jwtCookie);
    });
  }

  new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
  }).$mount('#app');
});
