/* eslint-disable global-require */
/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable import/no-unresolved */

import { JWT } from '@/types';
import axios from 'axios';
import jsonwebtoken from 'jsonwebtoken';

let publicKey = '';
try {
  publicKey = require('../certs/public.pem').default;
} catch (err) {
  // do nothing
}

/**
 * Decode a JWT token from a string;
 * @param token Token string to decode.
 */
export async function decodeToken(token: string): Promise<JWT> {
  if (!publicKey) {
    publicKey = (await axios.get(
      '/public.pem',
      { baseURL: `${window.location.origin}${process.env.BASE_URL}` },
    )).data;
  }
  return jsonwebtoken.verify(token, publicKey, { algorithms: ['RS256'] }) as JWT;
}

/**
 * Get's value from cookies.
 * @param name Name of the value.
 */
export function getCookie(name: string): string | undefined {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    return parts.pop()?.split(';').shift();
  }
  return undefined;
}

let tokenFromCookie: JWT | undefined; // eslint-disable-line import/no-mutable-exports
let cookie: string | undefined; // eslint-disable-line import/no-mutable-exports

export async function checkCookieToken(): Promise<void> {
  cookie = getCookie('btrl-jwt');
  if (cookie) {
    try {
      tokenFromCookie = await decodeToken(cookie);
    } catch (err) {
      // do nothing
    }
  }
}

export { tokenFromCookie as jwt, cookie as jwtCookie };
