import { API, Scopes } from '@/types';
import axios, { AxiosRequestConfig, Method } from 'axios';
import { jwtCookie } from './auth';
import { socket } from './socket';
import { serverURL } from './util';

// Scopes need both adding to this file and also src/types/Scopes.d.ts
export const allScopes: Scopes[] = [
  // Applications
  'applications:read',
  'applications:create',
  'applications:modify',
  'applications:delete',

  // Authorised Channels
  'authorised-channels:channels:read',
  'authorised-channels:channels:modify',
  'authorised-channels:channels:delete',
  'authorised-channels:stream_data:read',

  // Casters
  'casters:read',
  'casters:create',
  'casters:modify',
  'casters:delete',

  // Commercials
  'commercials:logs:read',
  'commercials:start',

  // Company Data
  'company-data:read',
  'company-data:modify',

  // Events
  'events:read',
  'events:read:private',
  'events:create',
  'events:modify',
  'events:delete',

  // Event Log
  'event-log:read',
  'event-log:create',
  'event-log:modify',
  'event-log:delete',
  'event-log:upload-file',

  // Images
  'images:read',
  'images:create',
  'images:modify',
  'images:delete',

  // Leaderboard Placements
  'leaderboard-placements:read',

  // LiveSplit
  'livesplit',

  // NodeCG
  'nodecg:trigger',
  'nodecg:listen',

  // Players
  'players:read',
  'players:create',
  'players:modify',
  'players:delete',

  // Player Cards
  'player-cards:read',
  'player-cards:create',
  'player-cards:modify',
  'player-cards:delete',

  // Player Data
  'player-data:read',

  // Player Order
  'player-order:read',
  'player-order:modify',

  // Run Attempts
  'run-attempts:read',
  'run-attempts:create',
  'run-attempts:modify',
  'run-attempts:delete',

  // Sponsors
  'sponsors:read',
  'sponsors:create',
  'sponsors:modify',
  'sponsors:delete',

  // Users
  'users:read',
  'users:create',
  'users:modify',
  'users:delete',

  // Videos
  'videos:available:read',
  'videos:available:modify',
  'videos:playlists:read',
  'videos:playlists:create',
  'videos:playlists:modify',
  'videos:playlists:delete',
  'videos:player-data:read',
  'videos:player-data:modify',
];

export function axiosConfig(): AxiosRequestConfig {
  const config: AxiosRequestConfig = {
    baseURL: serverURL(),
    headers: {},
  };
  if (jwtCookie && config.headers) {
    config.headers.Authorization = `Bearer ${jwtCookie}`;
  }
  if (socket.id && config.headers) {
    config.headers['Socket-ID'] = socket.id;
  }
  return config;
}

function generateUrlParams(params: { [k: string]: unknown }): string {
  return Object.entries(params).reduce((prev, [key, val], i) => {
    if (typeof val === 'undefined') {
      return prev;
    }
    return `${prev}${i > 0 ? '&' : '?'}${key}=${val}`;
  }, '');
}

/* eslint-disable max-len */
const endpoints: { name: keyof API.TypesMap, base: string, multi?: boolean }[] = [
  { name: 'applications', base: '/applications', multi: true },
  { name: 'authorisedChannels', base: '/authorised_channels/channels', multi: true },
  { name: 'availableVideos', base: '/videos/available', multi: true },
  { name: 'casters', base: '/casters', multi: true },
  { name: 'commercialLog', base: '/commercials/logs', multi: true },
  { name: 'companyData', base: '/company_data' },
  { name: 'eventLog', base: '/event_log', multi: true },
  { name: 'events', base: '/events', multi: true },
  { name: 'images', base: '/images', multi: true },
  { name: 'leaderboardPlacements', base: '/leaderboard_placements', multi: true },
  { name: 'livesplitData', base: '/livesplit/data', multi: true },
  { name: 'playerCards', base: '/player_cards', multi: true },
  { name: 'playerData', base: '/player_data', multi: true },
  { name: 'playerOrder', base: '/player_order', multi: true },
  { name: 'players', base: '/players', multi: true },
  { name: 'runAttempts', base: '/run_attempts', multi: true },
  { name: 'sponsors', base: '/sponsors', multi: true },
  { name: 'streamData', base: '/authorised_channels/stream_data', multi: true },
  { name: 'users', base: '/users', multi: true },
  { name: 'videoPlayerData', base: '/videos/player_data', multi: true },
  { name: 'videoPlaylists', base: '/videos/playlists', multi: true },
];
/* eslint-enable */

// Helper for API GET single endpoints.
export async function apiGETSingle<K extends keyof API.TypesMap>(
  name: K,
  id?: number | string,
  embed?: API.TypesMap[K]['embeds'] | API.TypesMap[K]['embeds'][],
): Promise<{ data: API.TypesMap[K]['returnedType'] }> {
  const endpoint = endpoints.find((e) => e.name === name);
  if (!endpoint) throw new Error('endpoint not found in list!');
  const { data }: { data: { data: API.TypesMap[K]['returnedType'] } } = await axios.get(
    `${id ? `${endpoint.base}/${id}` : endpoint.base}${generateUrlParams({ embed })}`,
    axiosConfig(),
  );
  return data;
}

// Helper for API GET multi endpoints.
export async function apiGETMulti<K extends keyof API.TypesMap>(
  name: K,
  params: Partial<API.TypesMap[K]['params']> & {
    max?: number,
    offset?: number,
    embed?: API.TypesMap[K]['embeds'] | API.TypesMap[K]['embeds'][],
  } = {},
  getAll = false,
): Promise<{ data: API.TypesMap[K]['returnedType'][] }> {
  const endpoint = endpoints.find((e) => e.name === name);
  if (!endpoint) throw new Error('endpoint not found in list!');
  if (!endpoint.multi) throw new Error('endpoint does not support multi!');
  let data: { data: API.TypesMap[K]['returnedType'][] } = { data: [] };
  if (!getAll) {
    const { data: resp }: { data: { data: API.TypesMap[K]['returnedType'][] } } = await axios
      .get(`${endpoint.base}${generateUrlParams(params)}`, axiosConfig());
    data = resp;
  } else {
    const newParams = { ...params, max: 100, offset: 0 };
    let nextPage = true;
    do {
      // eslint-disable-next-line no-await-in-loop
      const { data: resp }: { data: { data: API.TypesMap[K]['returnedType'][] } } = await axios
        .get(`${endpoint.base}${generateUrlParams(newParams)}`, axiosConfig());
      data.data = data.data.concat(resp.data);
      if (resp.data.length !== newParams.max) nextPage = false;
      else newParams.offset += newParams.max;
    } while (nextPage);
  }
  return data;
}

export async function apiModify<K extends keyof API.TypesMap>(
  name: K,
  id: number | string | null | undefined,
  body: API.TypesMap[K]['modifyType'],
  method?: Method,
): Promise<{ data: API.TypesMap[K]['returnedType'] }> {
  const endpoint = endpoints.find((e) => e.name === name);
  if (!endpoint) throw new Error('endpoint not found in list!');
  const { data }: { data: { data: API.TypesMap[K]['returnedType'] } } = await axios({
    ...axiosConfig(),
    ...{
      method: method || id ? 'PUT' : 'POST',
      url: id ? `${endpoint.base}/${id}` : endpoint.base,
      data: body,
    },
  });
  return data;
}

export async function apiDELETE<K extends keyof API.TypesMap>(
  name: K,
  id: number | string,
): Promise<void> {
  const endpoint = endpoints.find((e) => e.name === name);
  if (!endpoint) throw new Error('endpoint not found in list!');
  return axios.delete(`${endpoint.base}/${id}`, axiosConfig());
}
