import Vue from 'vue';
import Vuex from 'vuex';
import { namespace } from 'vuex-class';
import { getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';

Vue.use(Vuex);

@Module({ namespaced: true, name: 'RootModule' })
class RootModule extends VuexModule {
  loading = false;
  saved = false;
  selectedEvent: number | null = null;
  selectedPlayer: number | null = null;
  @Mutation setLoading(loading: boolean) { this.loading = loading; }
  @Mutation setSaved(saved: boolean) { this.saved = saved; }
  @Mutation setSelectedEvent(event: number | null) { this.selectedEvent = event; this.selectedPlayer = null; }
  @Mutation setSelectedPlayer(player: number | null) { this.selectedPlayer = player; }
}

export const store = new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  modules: { RootModule },
});

export const rootModule = getModule(RootModule, store);
export const rootNS = namespace('RootModule');
