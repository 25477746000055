




import { apiGETMulti } from '@/api';
import { jwt } from '@/auth';
import { rootModule } from '@/store';
import { Vue, Component, Watch } from 'vue-property-decorator';
import { storeModule } from './Dashboard/store';

@Component
export default class extends Vue {
  get isAuthed(): boolean {
    return !!jwt;
  }

  get selectedEvent(): number | null { return rootModule.selectedEvent; }
  @Watch('selectedEvent')
  async onSelectedEventChange(): Promise<void> {
    if (this.isAuthed) {
      await storeModule.loadEventAPIData();
      await storeModule.loadPlayerAPIData();
    }
  }

  async created(): Promise<void> {
    if (this.isAuthed) {
      const { data } = (await apiGETMulti('events', { max: 10 }));
      if (data.length) {
        const sorted = data
          .sort((a, b) => Date.parse(b.startingTimestamp) - Date.parse(a.startingTimestamp));
        rootModule.setSelectedEvent(sorted[0].id);
      }
    }
  }
}
