import { apiGETMulti, apiGETSingle } from '@/api';
import { rootModule, store } from '@/store';
import { API } from '@/types';
import Vue from 'vue';
import { namespace } from 'vuex-class';
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';

const moduleName = 'dashboard';
if (store.hasModule(moduleName)) store.unregisterModule(moduleName);

@Module({ dynamic: true, store, namespaced: true, name: moduleName })
export class StoreModule extends VuexModule {
  players: API.Players.GET[] = [];
  event: API.Events.GET | null = null;

  @Mutation
  setProperty<T>({ key, val }: { key: string, val: T }): void {
    Vue.set(this, key, val);
  }

  @Mutation
  updateStoredPlayer(player: API.Players.GET): void {
    const index = this.players.findIndex((p) => p.id === player.id);
    if (index >= 0) Vue.set(this.players, index, { ...this.players[index], ...player });
  }

  // Event data (for selected event).
  @Action
  async loadEventAPIData(): Promise<void> {
    if (!rootModule.selectedEvent) return;
    const { data } = await apiGETSingle('events', rootModule.selectedEvent);
    this.setProperty({ key: 'event', val: data });
  }
  @Mutation
  SOCKET_EVENTMODIFIED([val]: (API.Events.GET | null)[]): void {
    // TODO: Handle an event we have selected being deleted (rare).
    if (val?.id !== this.event?.id) return;
    Vue.set(this, 'event', { ...this.event, ...val });
  }

  // Players (for selected event).
  @Action
  async loadPlayerAPIData(): Promise<void> {
    if (!rootModule.selectedEvent) return;
    const { data } = await apiGETMulti('players', {
      offset: 0,
      embed: ['user'],
      eventId: rootModule.selectedEvent,
    }, true);
    this.setProperty({ key: 'players', val: data });
  }
  @Mutation
  updatePlayerFromSocketMsg([newVal, oldVal]: (API.Players.GET | null)[]): void {
    const index = this.players.findIndex((p) => p.id === oldVal?.id);
    if (index >= 0) {
      if (newVal) Vue.set(this.players, index, { ...this.players[index], ...newVal });
      else this.players.splice(index, 1);
    }
  }
  @Mutation
  addNewPlayer(player: API.Players.GET): void {
    this.players.unshift(player);
  }
  @Action
  async SOCKET_PLAYERMODIFIED([newVal, oldVal]: (API.Players.GET | null)[]): Promise<void> {
    if (oldVal && oldVal.eventId === rootModule.selectedEvent) {
      this.updatePlayerFromSocketMsg([newVal, oldVal]);
    } else if (newVal && newVal.eventId === rootModule.selectedEvent) {
      const { data } = await apiGETSingle('players', newVal.id, ['user']);
      this.addNewPlayer(data);
    }
  }
  @Mutation
  SOCKET_USERMODIFIED([newVal, oldVal]: (API.Users.GET | null)[]): void {
    this.players.forEach((player, i) => {
      if (newVal && oldVal && player.userId === oldVal.id) {
        Vue.set(this.players[i], 'user', { ...this.players[i].user, ...newVal });
      }
    });
  }
}

export const storeModule = getModule(StoreModule, store);
export const storeNS = namespace(moduleName);
